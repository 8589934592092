import { createAction, props } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  IAlternateLanguage,
  ICounterData,
  IRatingFeedback,
  Palettes,
  SettingsUI,
} from "src/app/models/kioskUiSettings.model";
import {
  IIndividualTaxDetails,
  IReceiptTemplate,
  // IStatus,
} from "src/app/models/config.model";
import { PrinterStatusRes } from "src/app/models/asset.modal";
import { IStatus } from "src/app/constants";

export const callConfigApi = createAction(
  "[Config API]  Call Config api",
  props<{ firsLoad?: boolean; accountId: string; token?: string,terminalId: string }>()
);

export const configApiSuccess = createAction(
  "[Config API]  Config api success",
  props<{ data: SettingsUI; firstLoad?: boolean; key: string }>()
);

export const configApiFailure = createAction(
  "[Config API]  Config api fail",
  props<{ error: Error; key: string }>()
);

export const changeSelectedLanguage = createAction(
  "[Config API]  Chaning selected language",
  props<{
    isRegionalLanguageSelected: boolean;
    key: string;
    defaultSet: boolean;
    language?: IAlternateLanguage;
  }>()
);

export const resetSessionTimer = createAction(
  "[Config API]  reset Session Timer",
  props<{ timerType?: string; key: string }>()
);

export const switchTheme = createAction(
  "[Config API]  changing UI theme",
  props<{ changeThemeTo: string; themePalettes: Palettes; key: string }>()
);

export const runSlotMatchInterval = createAction(
  "[Config API]  run interval to check time slots",
  props<{ slotTimes: string[] }>()
);

export const showBlockPopUp = createAction(
  "[Config API] prompt user about slot updation",
  props<{ visible: boolean; key: string }>()
);

export const checkPrinterStatusOnline = createAction(
  "[Printer Status API] checking printer status",
  props<{ enablePolling: boolean }>()
);
// props<{ printerStatusMsg: string | undefined; key: string }>();
export const updatePrinterStatus = createAction(
  "[Printer Status API] updating Printer Status",
  props<{ printerStatusMsg: string | undefined; key: string }>()
);

export const updatePrinterStatusOnline = createAction(
  "[Printer Status API] updating Printer Status online",
  props<{
    printerStatusMsg: PrinterStatusRes | HttpErrorResponse;
    key: string;
  }>()
);

export const stopPrinterPolling = createAction(
  "Printer Status API] to stop the polling",
  props<{ isPrinterPollingEnabled: boolean; key: string }>()
);

export const getTokenContent = createAction(
  "[Printer API] get token content",
  props<{ isOrderPaid: boolean; orderId: string; fromPaymentScreen: boolean }>()
);

export const getPrintInvoice = createAction(
  "[Printer API] get invoice content",
  props<{ isOrderPaid: boolean; orderId: string; fromPaymentScreen: boolean, pMode?: string }>()
);

export const callPrinterOnline = createAction(
  "[Printer API] printing online",
  props<{ invoiceContent: string; isOrderPaid: boolean; orderId: string }>()
);

export const callPrinterOffline = createAction(
  "[Printer API] printing offline",
  props<{ checkoutData: any; fromPaymentScreen: boolean }>()
);

export const printProcessFail = createAction(
  "[Printer API] printing fail ",
  props<{ error: HttpErrorResponse | string }>()
);

export const printSuccess = createAction("[Printer API] printing success ");

export const setprintPreviewImg = createAction(
  "[Printer API] print Preview api success ",
  props<{ imgUrl: string; key: string }>()
);

export const setPaymentModeOffline = createAction(
  "[Payment Mode api] Setting payment mode as offline",
  props<{ orderId: string }>()
);

export const dataUpdationFromSocket = createAction(
  "[Config setting] Storing the metadata for the updation",
  props<{
    accountId: string;
    store?: string;
    updatedData: string[];
    storeIds?: string[];
  }>()
);

export const getUpdatedData = createAction(
  "[Socket data updation ] get updated data"
);

export const restartSlotTimer = createAction("[Slot timer] restart slot timer");

export const switchDinningStatus = createAction(
  "[Dinning Status] Switch Dinning Status",
  props<{ isDining: boolean; resetToDefault?: boolean; key: string }>()
);

export const changePaymentGateway = createAction(
  "[Change payment gateway valye] payment gateway change",
  props<{ key: string; gateway: string }>()
);

export const submitRatingFeedback = createAction(
  "[Rating Feedback] submit rating feedback",
  props<{ obj: IRatingFeedback }>()
);

export const submitRatingFeedbackSuccess = createAction(
  "[Rating Feedback] submit rating feedback success",
  props<{ isRatingSubmitted: boolean }>()
);

export const getAllTaxDetails = createAction(
  "[Tax Details] get all tax details"
);

export const getAllTaxDetailsSuccess = createAction(
  "[Tax Details] get all tax details success",
  props<{ taxes: IIndividualTaxDetails[]; storeId: string }>()
);

export const getAllReceiptTemplates = createAction(
  "[Printing Templates] get all Printing Templates",
  props<{ terminalId: string }>()
);

export const getAllReceiptTemplatesSuccess = createAction(
  "[Printing Templates] get all Printing Templates success",
  props<{ templates: IReceiptTemplate; storeId: string }>()
);
export const setPrinterStatus = createAction(
  "[Kiosk Store] set printer statuss",
  props<{ status?: Record<string, IStatus>; event?: string }>()
);

export const setPrinterOffline = createAction(
  "[Kiosk Store ] set printer offline on error"
);

export const loadCounterData = createAction(
  "[Kiosk Store API] load counter list data",
);

export const loadCounterDataSuccess = createAction(
  "[Kiosk Store API] load counter list data success",
  props<{data: ICounterData[], key:string }>()
);

export const isAllowedGoHome = createAction(
  "[Kiosk Store] Offline page Go Home isAllowed",
  props<{isGoHomeAllowed: boolean }>()
);